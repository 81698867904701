import Navbar from "../blocks/navbar";
import Footer from "../blocks/footer";
import img_first from '../myFiles/images/ijt.png'
import img_second from '../myFiles/images/ishlabchiqarish.jpg'
import img_third from '../myFiles/images/acc.png'
import {useTranslation} from "react-i18next";

function ToLogin() {
    const {t, i18n} = useTranslation()

    return <div>
        <Navbar/>
        <div className={'page to-login'}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-4">
                        <div className="card">
                            <a href="https://ijtimoiybolim.uzkoj.uz/login/" target={'_blank'}>
                                <div className={'card-footer d-flex align-items-center justify-content-between'}>
                                    <h2 className={'mb-0'}>{t('social_department')}</h2>
                                    <span>{t('login')} »</span>
                                </div>
                                <div className={'toLoginImage'}>
                                    <img src={img_first} alt={t('social_department')}/>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="card">
                            <a href="https://ishlabchiqarish.uzkoj.uz/login/" target={'_blank'}>
                                <div className={'card-footer d-flex align-items-center justify-content-between'}>
                                    <h2 className={'mb-0'}>{t('production_department')}</h2>
                                    <span>{t('login')} »</span>
                                </div>
                                <div className={'toLoginImage'}>
                                    <img src={img_second} alt={t('production_department')}/>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
}

export default ToLogin