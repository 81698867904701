import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "./apiAction";

const slice = createSlice({
    name: 'contact_us',
    initialState: {message: {}},
    reducers:{
        SaveContactUs: (state,action)=>{
            state.message = true
        },
        FailContactUs: (state,action)=>{
            state.message = false
        },
        editSaved: (state,action) =>{
            state.message = {}
        },
    }
})

export const contactSave = (data) => apiCall({
    url: 'save-contact-message/',
    method: 'POST',
    onSuccess: slice.actions.SaveContactUs.type,
    onFail: slice.actions.FailContactUs.type,
    data,
})

export const {editSaved} = slice.actions
export default slice.reducer