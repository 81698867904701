import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "./apiAction";

const slice = createSlice({
    name: 'images',
    initialState: {images: []},
    reducers:{
        getImages: (state,action)=>{
            state.images = action.payload
        }
    }
})

export const getAllImages = () => apiCall({
    url: 'images/',
    method: 'GET',
    onSuccess: slice.actions.getImages.type
})


export default slice.reducer