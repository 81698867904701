import Footer from "../blocks/footer";
import Navbar from "../blocks/navbar";
import BreadcrumbPage from "../blocks/breadcrumb";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {getAboutText} from "../store/aboutUs";
import {connect} from "react-redux";
import {useEffect, useState} from "react";
import Loader from "../blocks/loader";

function AboutUs(props) {
    const {t, i18n} = useTranslation();
    const [about, setAbout] = useState(false)

    useEffect(()=>{
        props.getAboutText()
    }, [])

    useEffect(()=>{
        setAbout(props.about_us[0])
    }, [props.about_us])

    return (
        <div>
            <Navbar/>
            <BreadcrumbPage props={'about_society'}/>
            <div className={'page about'}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <h2>{t('logo_text_1')}<br/>{t('logo_text_2')}</h2>
                            {about ? <img
                                src={about.image} className={'about-img'}
                                alt="Ko'zi ojizlar jamiyati rasmi"/>: <Loader/>}
                            {about ?
                                <p dangerouslySetInnerHTML={{__html: about[`text_${i18n.language}`]}}/>
                                : <Loader/> }
                        </div>
                        <div className="col-lg-4">
                            <ul className="list-about-us sticky-top">
                                <li className={'active'}>
                                    <Link to={'/'}>{t('about_society')}<ArrowForwardIosIcon/></Link>
                                </li>
                                <li>
                                    <Link to={'/managment'}>{t('management')}<ArrowForwardIosIcon/></Link>
                                </li>
                                <li>
                                    <Link to={'/all-resources'}>{t('organizations')}<ArrowForwardIosIcon/></Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default connect(props=>props.about_us, {getAboutText})(AboutUs)