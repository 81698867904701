import Footer from "../blocks/footer";
import * as React from "react";
import Navbar from "../blocks/navbar";
import {useTranslation} from "react-i18next";
import BreadcrumbPage from "../blocks/breadcrumb";
import NewsBlock from "../blocks/newsBlock";
import {Link} from "react-router-dom";

function Events(){
    const {t, i18n} = useTranslation();

    return <div>
        <Navbar/>
        <BreadcrumbPage props={'events'}/>
        <div className={'page news'}>
            <div className="container">
                <div className={'news-header'}>
                    <h2>{t('events')}</h2>
                    <ul className={'news-filter'}>
                        <li>
                            <Link to={'/news'}>{t('our_news')}</Link>
                        </li>
                        <li className={'active dis-link'}>
                            <Link to={'#'}>{t('events')}</Link>
                        </li>
                    </ul>
                </div>
                <div className={'row'}>
                    <NewsBlock newsFilter={'events'}/>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
}
export default Events