import React, {useEffect, useState} from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import {connect} from "react-redux";
import {getAllImages} from "../store/images";


function GallerySlider(props) {

    const [images, setImages] = useState([])

    useEffect(()=>{
        props.getAllImages()
    }, [])

    useEffect(()=>{
        setImages(props.images.slice(0,8))
    }, [props.images])


    return (
        <>
            <Swiper
                breakpoints={{
                    240: {
                        slidesPerView: 1,
                    },
                    576: {
                        slidesPerView: 2,
                    },
                    768: {
                        slidesPerView: 3,
                    },
                    920: {
                        slidesPerView: 5,
                    },
                }}
                slidesPerView={5}
                spaceBetween={3}
                slidesPerGroup={1}
                loop={false}
                loopFillGroupWithBlank={false}
                className="mySwiper"

            >
                {images ? images.map((item, index)=>
                    <SwiperSlide key={index}>
                        <img src={item.photo} alt="img"/>
                    </SwiperSlide>
                ): ''}
            </Swiper>
        </>
    )
}

export default connect(props=>props.images, {getAllImages})(GallerySlider)