import Navbar from "../blocks/navbar";
import BreadcrumbPage from "../blocks/breadcrumb";
import Footer from "../blocks/footer";
import {connect} from "react-redux";
import {getFaqs} from "../store/faq";
import {useEffect, useState} from "react";
import Typography from '@mui/material/Typography';
import {useTranslation} from "react-i18next";
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useParams} from "react-router-dom";

function Faqs(props) {
    const {t, i18n} = useTranslation();
    const params = useParams()
    const [expanded, setExpanded] = useState(1);

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    const [faqs, setFaqs] = useState([])

    useEffect(() => {
        props.getFaqs()
    }, [])

    useEffect(() => {
        setFaqs(props.faqs)
    }, [props.faqs])

    useEffect(() => {
        if(params.id){
            setExpanded(parseInt(params.id))
        }
    }, [params])


    return <div>
        <Navbar/>
        <BreadcrumbPage props={'faqs'}/>
        <div className={'page faqs'}>
            <div className="container">
                <h2>{t('faqs')}</h2>
                <div>
                    {faqs.length ? faqs.map((item, index) =>
                        <Accordion key={index} expanded={expanded === item.id} onChange={handleChange(item.id)}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography>{item[`question_${i18n.language}`]}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    {item[`answer_${i18n.language}`]}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    ) : ''}
                </div>
            </div>
        </div>
        <Footer/>
    </div>
}

export default connect(props => props.faqs, {getFaqs})(Faqs)