import {ToastContainer} from "react-toastify";
import {useTranslation} from "react-i18next";
import * as React from "react";

function Footer() {
    const {t, i18n} = useTranslation();
    return <footer>
        <div className="container">
            <div className="row">
                <div className="col-lg-4">
                    <h3 className={'widget-title'}>
                        {t('logo_text_1')} {t('logo_text_2')}
                    </h3>
                    <p>{t('footer_text')}</p>
                </div>
                <div className="col-lg-4">
                    <h2 className={'widget-title'}>
                        {t('contact_for_us')}
                    </h2>
                    <div>
                        <h6>{t('hotline')}:<br/><span>{t('our_phone')}</span></h6>
                        <h6>{t('fax')}:<br/><span>{t('our_fax')}</span></h6>
                        <h6>{t('email')}:<br/><span>{t('our_email')}</span></h6>
                        <h6>{t('address')}:<br/><span>{t('our_address')}</span></h6>
                    </div>
                </div>
                <div className="col-lg-4">
                    <h2 className={'widget-title'}>
                        {t('our_position')}
                    </h2>
                    <div>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5995.586954014358!2d69.250144!3d41.291599!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x576333b21104d281!2zNDHCsDE3JzI5LjgiTiA2OcKwMTUnMDAuNSJF!5e0!3m2!1sru!2s!4v1669836518233!5m2!1sru!2s"
                            width="100%" height="250" loading="lazy"></iframe>
                    </div>
                </div>
                <div className="col-lg-12">
                    <p className={'text-center'}>© 2018-{new Date().getFullYear()}</p>
                </div>
            </div>
        </div>
        <ToastContainer/>
    </footer>
}

export default Footer