import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "./apiAction";

const slice = createSlice({
    name: 'news',
    initialState: {news: [], innerNews: {}},
    reducers:{
        getAllNews: (state,action)=>{
            state.news = action.payload
        },
        getAllFilteredNews: (state,action)=>{
            state.news = action.payload
        },
        getOneInnerNews: (state,action)=>{
            state.innerNews = action.payload
        },
        FailInnerNews: (state,action)=>{
            state.innerNews.error = 'Not Found!'
        },
    }
})

export const getNews = () => apiCall({
    url: 'news/',
    method: 'GET',
    onSuccess: slice.actions.getAllNews.type
})

export const getFilteredNews = (category) => apiCall({
    url: `news/?category=`+category,
    method: 'GET',
    onSuccess: slice.actions.getAllFilteredNews.type,
})

export const getInnerNews = (id) => apiCall({
    url: 'news/'+id,
    method: 'GET',
    onSuccess: slice.actions.getOneInnerNews.type,
    onFail: slice.actions.FailInnerNews.type
})

export default slice.reducer