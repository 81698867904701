import {configureStore} from '@reduxjs/toolkit'
import about_us from "./aboutUs"
import slider from "./slider"
import document from "./documents"
import photo_gallery from "./photoGallery"
import video_gallery from "./videoGallery"
import news from "./news"
import management from "./management"
import branches from "./allBranches"
import open_document from "./openDocuments"
import koj_companies from "./kojCompanies"
import images from "./images"
import resources from "./resources"
import faqs from "./faq"
import contact_us from "./contact"
import search from "./search"
import {api} from "./middleware/api";

export default configureStore({
    reducer: {
        about_us, slider, document, photo_gallery, video_gallery, news, management, branches, open_document, images,
        resources, faqs, contact_us, search, koj_companies
    },
    middleware: [
        api,
    ]
})