import Navbar from "../blocks/navbar";
import {useTranslation} from "react-i18next";
import Footer from "../blocks/footer";

function NotFound(){
    const {t, i18n} = useTranslation();
    return <div>
        <Navbar/>
        <div className="page notFound">
            <h2>{t('notFound')}</h2>
        </div>
        <Footer/>
    </div>
}
export default NotFound