import Navbar from "../blocks/navbar";
import BreadcrumbPage from "../blocks/breadcrumb";
import SimpleReactLightbox from "simple-react-lightbox";
import GalleryBlock from "../blocks/galleryBlock";
import Footer from "../blocks/footer";
import * as React from "react";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {connect} from "react-redux";
import {getOneGallery} from "../store/photoGallery";

function GallerySingle(props) {
    const {t, i18n} = useTranslation();
    const params = useParams()
    const [galleryItem, setGalleryItem] = useState(false)

    useEffect(()=>{
        props.getOneGallery(params.id)
    }, [params])

    useEffect(()=>{
        setGalleryItem(props.single_gallery)
    }, [props])


    return <div>
        <Navbar/>
        {galleryItem&&galleryItem.id ?
            <BreadcrumbPage props={[{"title": 'photogallery', "link": "/photo-gallery/"}, {
                "title": galleryItem[`title_${i18n.language}`],
                "link": ""
            }]}/>
            : ''}
        {galleryItem ?
            <div className={'page single-gallery'}>
                <div className="container">
                    <h2>{galleryItem[`title_${i18n.language}`]}</h2>
                    <SimpleReactLightbox>
                        <GalleryBlock galleryItem={galleryItem.photos}/>
                    </SimpleReactLightbox>
                </div>
            </div> : ''}
        <Footer/>
    </div>
}

export default connect(props=>props.photo_gallery, {getOneGallery})(GallerySingle)