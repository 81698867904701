import Navbar from "../blocks/navbar";
import BreadcrumbPage from "../blocks/breadcrumb";
import * as React from "react";
import Footer from "../blocks/footer";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import ReactPaginate from "react-paginate";
import {connect} from "react-redux";
import {getAllGallery} from "../store/photoGallery";
import {format} from "date-fns";


function PhotoGallery(props) {
    const {t, i18n} = useTranslation();
    const [gallery, setGallery] = useState([])

    useEffect(()=>{
        props.getAllGallery()
    }, [])

    useEffect(()=>{
        setGallery(props.photo_gallery)
    }, [props])

    // Pagination

    const [itemsPerPage, setItemsPerPage] = useState(8)

    const [itemOffset, setItemOffset] = useState(0);

    const endOffset = itemOffset + itemsPerPage;
    const currentItems = gallery.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(gallery.length / itemsPerPage);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % gallery.length;
        setItemOffset(newOffset);
    };

    return <div>
        <Navbar/>
        <BreadcrumbPage props={'photogallery'}/>
        <div className={'page'}>
            <div className="container">
                <h2>{t('photogallery')}</h2>
                <div className={'gallery-block'}>
                    <div className="row">
                        {currentItems.length ? currentItems.map((item, index) =>
                                <div className={'col-lg-6'} key={index}>
                                    <Link to={"/photo-gallery/"+item.id}>
                                        <img src={item.image} alt={item[`title_${i18n.language}`]}/>
                                        <p style={{pointerEvents: 'none'}}>
                                            <span className={'date-gallery'}>{format(new Date(item.upload_to), 'dd/MM/yyyy')}</span>
                                            <span>{item[`title_${i18n.language}`]}</span></p>
                                    </Link>
                                </div>
                            )
                            : ''}
                        {pageCount > 1 ? <div className={'pagination-news'}>
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel=">"
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={5}
                                pageCount={pageCount}
                                previousLabel="<"
                                renderOnZeroPageCount={null}
                            />
                        </div> : ''}
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
}

export default connect(props=>props.photo_gallery, {getAllGallery})(PhotoGallery)