import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "./apiAction";

const slice = createSlice({
    name: 'slider',
    initialState: {sliders: []},
    reducers:{
        getSliders: (state,action)=>{
            state.sliders = action.payload
        }
    }
})

export const getAllSliders = () => apiCall({
    url: 'slider/',
    method: 'GET',
    onSuccess: slice.actions.getSliders.type
})


export default slice.reducer