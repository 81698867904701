import React, {useEffect, useState} from 'react'
import Marquee from "react-fast-marquee";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {getNews} from "../store/news";
import {useTranslation} from "react-i18next";

function TickerNews(props) {
    const {t, i18n} = useTranslation();
    const [posts, setPosts] = useState([])
    const { innerWidth: width } = window;
    useEffect(()=>{
        props.getNews()
    }, [])

    useEffect(()=>{
        setPosts(props.news)
    }, [props.news])


    return (
        <Marquee speed={(width < 768) ? 15 : 40} gradient={false}>
            {posts.length ? posts.slice(0,6).map((item,index) =>
                <Link to={item.category === 1 ? '/news/'+item.id : '/events/'+item.id} key={index}>
                    {item[`title_${i18n.language}`]}
                </Link>
            ) : <Link to="#">{t('news')}</Link>}
        </Marquee>
    )
}

export default connect(props => props.news, {getNews})(TickerNews)