import {SRLWrapper} from "simple-react-lightbox";
import {useEffect, useState} from "react";

function GalleryBlock(props) {

    const [gallery, setGallery] = useState(false)

    useEffect(()=>{
        setGallery(props.galleryItem)
    }, [props.galleryItem])

    const options = {
        thumbnails: {
            showThumbnails: false,
        }
    };


    return <SRLWrapper options={options}>
        <div className={'gallery-block'}>
            <div className="row">
                {gallery ? gallery.map((item, index) =>
                        <div className={'col-lg-3'} key={index}>
                            <a href={item.photo}>
                                <img src={item.photo}/>
                            </a>
                        </div>
                    )
                    : ''}
            </div>
        </div>
    </SRLWrapper>
}

export default GalleryBlock