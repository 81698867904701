import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "./apiAction";

const slice = createSlice({
    name: 'photo_gallery',
    initialState: {photo_gallery: [], single_gallery: []},
    reducers:{
        getGallery: (state,action)=>{
            state.photo_gallery = action.payload
        },
        getSingleGallery: (state,action)=>{
            state.single_gallery = action.payload
        }
    }
})

export const getAllGallery = () => apiCall({
    url: 'photo-gallery/',
    method: 'GET',
    onSuccess: slice.actions.getGallery.type
})

export const getOneGallery = (id) => apiCall({
    url: 'photo-gallery/'+id,
    method: 'GET',
    onSuccess: slice.actions.getSingleGallery.type
})


export default slice.reducer