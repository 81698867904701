import Modal from '@mui/material/Modal';
import {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import CloseIcon from '@mui/icons-material/Close';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    height: '90%',
    overflow: 'auto',
    bgcolor: 'background.paper',
    boxShadow: 24,
};

export default function VideoModal(props) {
    useEffect(()=>{
        setOpen(props.isOpen)
        setOpenItem(props.openItem)
    }, [props.isOpen])

    const [open, setOpen] = useState(true);
    const [openItem, setOpenItem] = useState(false);

    function handleClose(){
        setOpen(false);
        if(props && props.setIsOpen){
            props.setIsOpen(false)
            props.setOpenItem(false)
        }
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box sx={style} className={'video-modal'}>
                {openItem ? <iframe width="100%" height="100%" src={openItem.link} frameBorder="0"
                                    allowFullScreen=""/> : ''}
                <span className={'close-video'} onClick={handleClose}><CloseIcon/></span>
            </Box>
        </Modal>
    );
}