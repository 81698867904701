import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "./apiAction";

const slice = createSlice({
    name: 'document',
    initialState: {documents: []},
    reducers:{
        getDocuments: (state,action)=>{
            state.documents = action.payload
        }
    }
})

export const getAllDocuments = () => apiCall({
    url: 'documents/',
    method: 'GET',
    onSuccess: slice.actions.getDocuments.type
})


export default slice.reducer