import Navbar from "../blocks/navbar";
import BreadcrumbPage from "../blocks/breadcrumb"
import Footer from "../blocks/footer";
import * as React from "react";
import {useTranslation} from "react-i18next";
import VisibilityIcon from '@mui/icons-material/Visibility';
import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import ReactPaginate from "react-paginate";
import {connect} from "react-redux";
import {getAllDocuments} from "../store/documents";
import {format} from "date-fns";

function Documents(props){

    const {t, i18n} = useTranslation();
    const [documents, setDocuments] = useState([])


    useEffect(()=>{
        props.getAllDocuments()
    }, [])


    useEffect(()=>{
        setDocuments(props.documents)
    }, [props])

    // Pagination

    const [itemsPerPage, setItemsPerPage] = useState(12)

    const [itemOffset, setItemOffset] = useState(0);

    const endOffset = itemOffset + itemsPerPage;
    const currentItems = documents.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(documents.length / itemsPerPage);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % documents.length;
        setItemOffset(newOffset);
    };

    return <div>
        <Navbar/>
        <BreadcrumbPage props={'documents_title'}/>
        <div className={'page documents'}>
            <div className="container">
                <h2>{t('documents_title')}</h2>
                <div>
                    <table className="table table-striped table-borderless">
                        <thead>
                        <tr>
                            <th scope="col">№</th>
                            <th scope="col">{t('type_document')}</th>
                            <th scope="col">{t('date')}</th>
                            <th scope="col">{t('document_name')}</th>
                            <th scope="col">{t('see')}</th>
                        </tr>
                        </thead>
                        <tbody role={'rowgroup'}>
                        {currentItems.length ? currentItems.map((item, index)=><tr key={index} role={'row'}>
                            <td aria-label={'№'} style={{fontWeight: '600'}}><div>{itemOffset+index+1}</div></td>
                            <td aria-label={t('type_document')}><div>{item[`document_type_${i18n.language}`]}</div></td>
                            <td aria-label={t('date')}><div>{format(new Date(item.upload_date), 'dd/MM/yyyy')}</div></td>
                            <td aria-label={t('document_name')}><div>{item[`title_${i18n.language}`]}</div></td>
                            <td aria-label={t('see')}><a href={item.file ? item.file : item.url ? item.url : ''} target={'_blank'}><VisibilityIcon/>{t('see')}</a></td>
                        </tr>) : <tr/>}
                        </tbody>
                    </table>
                    {pageCount > 1 ? <div className={'pagination-news'}>
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel=">"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={5}
                            pageCount={pageCount}
                            previousLabel="<"
                            renderOnZeroPageCount={null}
                        />
                    </div> : ''}
                </div>
            </div>
        </div>
        <Footer/>
    </div>
}
export default connect(({document: {documents}}) => ({documents}), {getAllDocuments})(Documents)