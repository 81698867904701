import {useEffect, useState} from 'react';
import {
    Nav,
    NavLink,
    NavMenu,
    NavBtn,
    NavBtnLink,
} from './NavbarElements';
import {Link, useLocation} from "react-router-dom";
import logo from '../myFiles/images/logo.png'
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import TelegramIcon from '@mui/icons-material/Telegram';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from "@mui/icons-material/Search";
import TwitterIcon from '@mui/icons-material/Twitter';
import LoginIcon from '@mui/icons-material/Login';

import {useTranslation} from 'react-i18next';
import SearchBlock from "./searchBlock";

function Navbar() {
    const {t, i18n} = useTranslation();

    function changeLang(lang) {
        i18n.changeLanguage(lang)
    }

    const [searchBlock, setSearchBlock] = useState(false)
    const [changeSettings, setChangeSettings] = useState(false)
    const [changeColor, setChangeColor] = useState('normal')
    const [fontSize, setFontSize] = useState('font-1')

    useEffect(() => {
        window.scrollTo(0, 0)
        const font = window.localStorage.getItem('FONT_SIZE');
        const color = window.localStorage.getItem('SITE_COLOR');
        if(font !== null) {
            setFontSize(JSON.parse(font))
        }
        if(color !== null) {
            setChangeColor(JSON.parse(color))
        }
    }, []);

    useEffect(() => {
        var root = document.getElementsByTagName('html')[0]
        if (changeColor === 'normal') {
            root.classList.remove('invert');
            root.classList.remove('grayscale');
        } else if (changeColor === 'grayscale') {
            root.classList.remove('normal');
            root.classList.remove('invert');
        } else {
            root.classList.remove('grayscale');
            root.classList.remove('normal');
        }
        window.localStorage.setItem('SITE_COLOR', JSON.stringify(changeColor));
        root.classList.add(changeColor)
    }, [changeColor]);

    useEffect(() => {
        var root = document.body
        if (fontSize === 'font-1') {
            root.classList.remove('font-2');
            root.classList.remove('font-3');
        } else if (fontSize === 'font-2') {
            root.classList.remove('font-1');
            root.classList.remove('font-3');
        } else {
            root.classList.remove('font-2');
            root.classList.remove('font-1');
        }

        window.localStorage.setItem('FONT_SIZE', JSON.stringify(fontSize));
        root.classList.add(fontSize)
    }, [fontSize]);

    let bodyHome = false
    const [barActive, setBarActive] = useState(false)
    const thisLocation = useLocation();
    if (thisLocation.pathname === '/') {
        document.body.classList.add('home');
        bodyHome = document.body.classList.contains('home')
    } else {
        document.body.classList.remove('home');
    }

    function mobileAddClassActive(e){
        e.target.parentNode.classList.toggle('active')
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        window.addEventListener('scroll', handleContentLoaded);
    }, []);

    const handleContentLoaded = () => {
        if (window.scrollY > 180) {
            document.getElementById('nav_menu').classList.add('fixed-top');
            // add padding top to show content behind navbar
            if (!document.body.classList.contains('home')) {
                document.body.style.paddingTop = '40px';
            }
        } else {
            document.getElementById('nav_menu').classList.remove('fixed-top');
            // remove padding top from body
            document.body.style.paddingTop = '0';
        }
    }

    function toggle() {
        setBarActive(!barActive)
    }

    if (barActive === true) {
        document.body.classList.add('overflow-hidden');
    } else {
        document.body.classList.remove('overflow-hidden');
    }


    return (
        <div className={''}>
            <div className={'nav-head-mobile'}>
                <div className={'mobile-navbar-menu'}>
                    <button aria-label={'Open Menu'} onClick={toggle} className={barActive ? `mobile-bar menu_activated` : 'mobile-bar'}>
                        <div className={'content-mobile-menu'}>
                    <span className="menu__hamburger m-hamburger">
                        <span className="m-hamburger__label">
                            <span className="menu__toggle-hint screen-reader"/>
                        </span>
                    </span>
                        </div>
                    </button>
                </div>
                <NavLink className={'logo'} to='/'>
                    <img src={logo} className={'logo-home'} alt='logo'/>
                    {/*<span className={'logo-desc'}>*/}
                    {/*{t('logo_text_1')}<br/>{t('logo_text_2')}*/}
                    {/*</span>*/}
                </NavLink>
            </div>
            <div className={'topbar'}>
                <div className="container">
                    <div className="topbar-menu">
                        <ul className={'lang-menu'}>
                            <li className={`${i18n.language === 'oz' ? 'active' : ''}`}
                                onClick={() => changeLang('oz')}>O'zb
                            </li>
                            <li className={`${i18n.language === 'uz' ? 'active' : ''}`}
                                onClick={() => changeLang('uz')}>Ўзб
                            </li>
                            <li className={`${i18n.language === 'ru' ? 'active' : ''}`}
                                onClick={() => changeLang('ru')}>Рус
                            </li>
                            <li className={`${i18n.language === 'en' ? 'active' : ''}`}
                                onClick={() => changeLang('en')}>Eng
                            </li>
                        </ul>
                        <div>
                    <span className={`${changeSettings ? 'active' : ''} change-settings`}>
                        <span onClick={() => {
                            setChangeSettings(!changeSettings)
                        }}><VisibilityIcon/>{t('special opportunities')}<ExpandMoreIcon/></span>
                        <div className={`${changeSettings ? 'active' : ''}`}>
                        <div className={'text-size'}>
                        <span>{t('text_size')}</span>
                        <ul>
                            <li onClick={() => {
                                setFontSize('font-1')
                            }}>A</li>
                            <li onClick={() => {
                                setFontSize('font-2')
                            }}>A</li>
                            <li onClick={() => {
                                setFontSize('font-3')
                            }}>A</li>
                        </ul>
                    </div>
                        <div className={'bg-color-changer'}>
                        <span>{t('website_color')}</span>
                        <ul>
                            <li onClick={() => {
                                setChangeColor('normal')
                            }}>A</li>
                            <li onClick={() => {
                                setChangeColor('grayscale')
                            }}>A</li>
                            <li onClick={() => {
                                setChangeColor('invert')
                            }}>A</li>
                        </ul>
                    </div>
                        </div>
                    </span>
                            <div className={'social-block'}>
                                <ul>
                                    <li><Link to="#"><TelegramIcon/></Link></li>
                                    <li><Link to="#"><InstagramIcon/></Link></li>
                                    <li><Link to="#"><FacebookIcon/></Link></li>
                                    <li><Link to="#"><YouTubeIcon/></Link></li>
                                    <li><Link to="#"><TwitterIcon/></Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Nav id={'navbar_top'} className={'site-navigation home-navigation'}>
                <div className="container">
                    <div className={'nav-head'}>
                        <NavLink className={'logo'} to='/'>
                            <img src={logo} className={'logo-home'} alt='logo'/>
                            {/*<span className={'logo-desc'}>*/}
                            {/*{t('logo_text_1')}<br/>{t('logo_text_2')}*/}
                            {/*</span>*/}
                        </NavLink>
                    </div>
                    <div className={'nav-head-mobile'}>
                        <div className={'mobile-navbar-menu'}>
                            <button aria-label={'Open Menu'} onClick={toggle}
                                    className={barActive ? `mobile-bar menu_activated` : 'mobile-bar'}>
                                <div className={'content-mobile-menu'}>
                    <span className="menu__hamburger m-hamburger">
                        <span className="m-hamburger__label">
                            <span className="menu__toggle-hint screen-reader"/>
                        </span>
                    </span>
                                </div>
                            </button>
                        </div>
                        <NavLink className={'logo'} to='/'>
                            <img src={logo} alt='logo'/>
                        </NavLink>
                    </div>
                    <NavBtn>
                        <NavBtnLink className={'call-block'} to='/'>
                            <PhoneInTalkIcon/>
                            <span>71 255-65-59<br/><span>{t('call_center')}</span></span>
                        </NavBtnLink>
                        <Link className={'to-login-block'} to='/to-login'>
                            <LoginIcon/>
                        </Link>
                    </NavBtn>
                </div>
            </Nav>
            <NavMenu id={'nav_menu'} className={barActive ? `navbar-menu active` : 'navbar-menu'}>
                <div className="container">
                    <ul className={'lang-menu'}>
                        <li className={`${i18n.language === 'oz' ? 'active' : ''}`}
                            onClick={() => changeLang('oz')}>O'zb
                        </li>
                        <li className={`${i18n.language === 'uz' ? 'active' : ''}`}
                            onClick={() => changeLang('uz')}>Ўзб
                        </li>
                        <li className={`${i18n.language === 'ru' ? 'active' : ''}`}
                            onClick={() => changeLang('ru')}>Рус
                        </li>
                        <li className={`${i18n.language === 'en' ? 'active' : ''}`}
                            onClick={() => changeLang('en')}>Eng
                        </li>
                    </ul>
                    <div className={'w-100 mobile-block'}><SearchBlock/></div>
                    <ul>
                        <li onClick={mobileAddClassActive}>
                            <NavLink className={'link-header'} disabled>
                                {t('about_society')}<ExpandMoreIcon/>
                            </NavLink>
                            <ul className={'submenu'}>
                                <li>
                                    <NavLink className={'link-header'} to='/about-us/'>
                                        {t('about_society')}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink className={'link-header'} to='/managment/'>
                                        {t('management')}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink className={'link-header'} to='/all-resources'>
                                        {t('organizations')}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink className={'link-header'} to='/faqs'>
                                        {t('faqs')}
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <NavLink className={'link-header'} to='/documents'>
                                {t('documents_title')}
                            </NavLink>
                        </li>
                        <li onClick={mobileAddClassActive}>
                            <NavLink className={'link-header'} disabled>
                                {t('news')}<ExpandMoreIcon/>
                            </NavLink>
                            <ul className={'submenu'}>
                                <li>
                                    <NavLink className={'link-header'} to='/news'>
                                        {t('news')}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink className={'link-header'} to='/events'>
                                        {t('events')}
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                        <li onClick={mobileAddClassActive}>
                            <NavLink className={'link-header'} disabled>
                                {t('medigallery')}<ExpandMoreIcon/>
                            </NavLink>
                            <ul className={'submenu'}>
                                <li>
                                    <NavLink className={'link-header'} to='/photo-gallery'>
                                        {t('photogallery')}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink className={'link-header'} to='/video-gallery'>
                                        {t('videogallery')}
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                        <li onClick={mobileAddClassActive}>
                            <NavLink className={'link-header'} disabled>
                                {t('open_documents')}<ExpandMoreIcon/>
                            </NavLink>
                            <ul className={'submenu'}>
                                <li>
                                    <NavLink className={'link-header'} to='/open-data'>
                                        {t('open_documents')}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink className={'link-header'} to='/uzkoj-companies'>
                                        {t('koj_companies')}
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <NavLink className={'link-header'} to='/contact-us'>
                                {t('contact_us')}
                            </NavLink>
                        </li>
                    </ul>
                    <NavBtn className={'mobile-block phone-btn'}>
                        <NavBtnLink className={'call-block'} to='/'>
                            <span>
                                <span>
                                    <PhoneInTalkIcon/>
                                    71 255-65-59
                                </span>
                                <span>{t('call_center')}
                            </span>
                            </span>
                        </NavBtnLink>
                    </NavBtn>
                    <span className={'search-btn'} onClick={() => {
                        setSearchBlock(!searchBlock)
                    }}>
                        <SearchIcon/>
                    </span>
                </div>
                {searchBlock ? <SearchBlock/> : ''}
            </NavMenu>
        </div>
    );
};

export default Navbar;