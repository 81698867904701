import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "./apiAction";

const slice = createSlice({
    name: 'management',
    initialState: {management: []},
    reducers:{
        getAllPersonManagement: (state,action)=>{
            state.management = action.payload
        }
    }
})

export const getPersonManagement = () => apiCall({
    url: 'management/',
    method: 'GET',
    onSuccess: slice.actions.getAllPersonManagement.type
})


export default slice.reducer