import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "./apiAction";

const slice = createSlice({
    name: 'about_us',
    initialState: {about_us: []},
    reducers:{
        getAboutUs: (state,action)=>{
            state.about_us = action.payload
        }
    }
})

export const getAboutText = () => apiCall({
    url: 'about-us/',
    method: 'GET',
    onSuccess: slice.actions.getAboutUs.type
})


export default slice.reducer