import Navbar from "../blocks/navbar";
import BreadcrumbPage from "../blocks/breadcrumb";
import Footer from "../blocks/footer";
import {useTranslation} from "react-i18next";
import {Link, useParams} from "react-router-dom";
import * as React from "react";
import {useEffect, useState} from "react";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import {getInnerNews, getNews} from "../store/news";
import {connect} from "react-redux";
import {format} from "date-fns";
import Loader from "../blocks/loader";

function NewsInner(props) {
    const {t, i18n} = useTranslation();
    const params = useParams()
    const [newsItem, setNewsItem] = useState(false)
    const [news, setNews] = useState(false)


    useEffect(()=>{
        props.getNews()
        props.getInnerNews(params.id)
        window.scrollTo(0, 0)
    }, [params])

    useEffect(() => {
        setNewsItem(props.innerNews)
    }, [props.innerNews.id])


    useEffect(() => {
        setNews(props.news.slice(0, 6))
    }, [props.news.length])


    return <div>
        <Navbar/>
        <BreadcrumbPage props={[{"title": 'news', "link": "/news/"}, {
            "title": newsItem[`title_${i18n.language}`],
            "link": ""
        }]}/>
        <div className={'page single'}>
            <div className="container">
                <div className="row">
                    {newsItem.id ? <div className="col-lg-9">
                        <img src={newsItem.photo} alt={newsItem[`title_${i18n.language}`]} className={'inner-post-image'}/>
                        <span className={'date-news-inner'}><CalendarMonthIcon/>{format(new Date(newsItem.upload_to), 'dd/MM/yyyy')}</span>
                        <h2 className={'inner-news-title'}>{newsItem[`title_${i18n.language}`]}</h2>
                        <p dangerouslySetInnerHTML={{__html: newsItem[`text_${i18n.language}`]}}/>
                    </div> : <Loader/>}
                    <div className="col-lg-3">
                        <div className="last-news sticky-top">
                            <h3>{t('latest_news')}</h3>
                            <ul className={'last-news-list'}>
                                {news ? news.map((item, index)=> <li key={index}>
                                        <Link to={item.category === 1 ? '/news/'+item.id : '/events/'+item.id}>
                                            {item[`title_${i18n.language}`]}
                                        </Link>
                                    </li>
                                    ) : <Loader/>}
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <Footer/>
    </div>
}

export default connect(props=>props.news, {getNews, getInnerNews})(NewsInner)