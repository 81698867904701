import Map from "../map/map";
import Navbar from "../blocks/navbar";
import BreadcrumbPage from "../blocks/breadcrumb";
import {Link} from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Footer from "../blocks/footer";
import {useTranslation} from "react-i18next";


function AllBranches(props){
    const {t, i18n} = useTranslation();

    return <div>
        <Navbar/>
        <BreadcrumbPage props={'organizations'}/>
        <div className={'page'}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-8">
                        <h2>{t('organizations')}</h2>
                        <Map/>
                    </div>
                    <div className="col-lg-4">
                        <ul className="list-about-us sticky-top">
                            <li>
                                <Link to={'/about-us'}>{t('about_society')}<ArrowForwardIosIcon/></Link>
                            </li>
                            <li>
                                <Link to={'/managment'}>{t('management')}<ArrowForwardIosIcon/></Link>
                            </li>
                            <li className={'active'}>
                                <Link to={'/all-resources'}>{t('organizations')}<ArrowForwardIosIcon/></Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
}
export default AllBranches