import Navbar from "../blocks/navbar";
import BreadcrumbPage from "../blocks/breadcrumb"
import Footer from "../blocks/footer";
import * as React from "react";
import {useTranslation} from "react-i18next";
import VisibilityIcon from '@mui/icons-material/Visibility';
import {useEffect, useState} from "react";
import ReactPaginate from "react-paginate";
import {format} from "date-fns";
import {connect} from "react-redux";
import {getAllKojCompanies} from "../store/kojCompanies";

function KojCompanies(props){
    const {t, i18n} = useTranslation();
    const [documents, setDocuments] = useState([])

    useEffect(()=>{
        props.getAllKojCompanies()
    }, [])


    useEffect(()=>{
        setDocuments(props.koj_companies)
    }, [props])

    // Pagination

    const [itemsPerPage, setItemsPerPage] = useState(12)

    const [itemOffset, setItemOffset] = useState(0);

    const endOffset = itemOffset + itemsPerPage;
    const currentItems = documents.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(documents.length / itemsPerPage);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % documents.length;
        setItemOffset(newOffset);
        window.scrollTo(0, 0)
    };


    return <div>
        <Navbar/>
        <BreadcrumbPage props={'koj_companies'}/>
        <div className={'page documents companies_koj'}>
            <div className="container">
                <h2>{t('koj_companies')}</h2>
                <div>
                    <h4 className={'text-center'}>{t('info_title')}</h4>
                    <h4 className={'text-uppercase text-center'}>{t('info_title_upper')}</h4>
                    <h5 className={'text-center'}>{t('info_desc')}</h5>
                    <table className="table table-striped table-borderless table-bordered">
                        <thead>
                        <tr>
                            <th scope="col">№</th>
                            <th scope="col">{t('title')}</th>
                            <th scope="col">{t('date_modified')}</th>
                            <th scope="col">{t('comp_number')}</th>
                            <th scope="col">{t('stir')}</th>
                            <th scope="col">{t('address_comp')}</th>
                            <th scope="col">{t('phone_number_comp')}</th>
                            <th scope="col">{t('members')}</th>
                            <th scope="col">{t('invalid_members')}</th>
                            <th scope="col">{t('invalid_members1')}</th>
                            <th scope="col">{t('invalid_members2')}</th>
                            <th scope='col'>
                                {t('text_title')}
                                <th style={{width: '300px'}} scope="col">{t('text1')}</th>
                                <th style={{width: '300px'}} scope="col">{t('text2')}</th>
                            </th>
                            {/*<th scope="col">{t('sums')}</th>*/}
                        </tr>
                        </thead>
                        <tbody role={'rowgroup'}>
                        {currentItems.length ? currentItems.map((item, index)=><tr key={index} role={'row'}>
                            <td aria-label={'№'} style={{fontWeight: '600'}}><div>{itemOffset+index+1}</div></td>
                            <td aria-label={t('title')}><div>{item[`title_${i18n.language}`]}</div></td>
                            <td aria-label={t('date_modified')}><div>{format(new Date(item.date_modified), 'dd/MM/yyyy')}</div></td>
                            <td aria-label={t('comp_number')}><div>{item[`comp_number`]}</div></td>
                            <td aria-label={t('stir')}><div>{item[`stir`]}</div></td>
                            <td aria-label={t('address')}><div>{item[`address_${i18n.language}`]}</div></td>
                            <td aria-label={t('phone_number')}><div>{item[`phone_number`]}</div></td>
                            <td aria-label={t('members')}><div>{item[`members`]}</div></td>
                            <td aria-label={t('invalid_members')}><div>{item[`invalid_members`]}</div></td>
                            <td aria-label={t('invalid_members1')}><div>{item[`invalid_members1`]}</div></td>
                            <td aria-label={t('invalid_members2')}><div>{item[`invalid_members2`]}</div></td>
                            <td>
                                <td style={{width: '300px'}} aria-label={t('text1')}><div>{item[`text1_${i18n.language}`]}</div></td>
                                <td style={{width: '300px'}} aria-label={t('text2')}><div>{item[`text2_${i18n.language}`]}</div></td>
                            </td>
                            {/*<td aria-label={t('sums')}><div>{item[`sums`]}</div></td>*/}
                        </tr>) : <tr/>}
                        </tbody>
                    </table>
                    {pageCount > 1 ? <div className={'pagination-news'}>
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel=">"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={5}
                            pageCount={pageCount}
                            previousLabel="<"
                            renderOnZeroPageCount={null}
                        />
                    </div> : ''}
                </div>
            </div>
        </div>
        <Footer/>
    </div>
}
export default connect(({koj_companies: {koj_companies}}) => ({koj_companies}), {getAllKojCompanies})(KojCompanies)