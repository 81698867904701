import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "./apiAction";

const slice = createSlice({
    name: 'search',
    initialState: {documents: [], open_documents: [], news: [], faqs: []},
    reducers:{
        searchDocuments: (state,action)=>{
            state.documents = action.payload
        },
        searchOpenDocuments: (state,action)=>{
            state.open_documents = action.payload
        },
        searchNews: (state,action)=>{
            state.news = action.payload
        },
        searchFaqs: (state,action)=>{
            state.faqs = action.payload
        }
    }
})

export const getSearchDocuments = (value) => apiCall({
    url: 'documents/?search='+value,
    method: 'GET',
    onSuccess: slice.actions.searchDocuments.type
})
export const getSearchOpenDocuments = (value) => apiCall({
    url: 'open-data/?search='+value,
    method: 'GET',
    onSuccess: slice.actions.searchOpenDocuments.type
})
export const getSearchNews = (value) => apiCall({
    url: 'news/?search='+value,
    method: 'GET',
    onSuccess: slice.actions.searchNews.type
})
export const getSearchFaqs = (value) => apiCall({
    url: 'faqs/?search='+value,
    method: 'GET',
    onSuccess: slice.actions.searchFaqs.type
})

export default slice.reducer