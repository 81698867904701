import Navbar from "../blocks/navbar";
import BreadcrumbPage from "../blocks/breadcrumb";
import * as React from "react";
import Footer from "../blocks/footer";
import {useTranslation} from "react-i18next";
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';
import FaxIcon from '@mui/icons-material/Fax';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import {createRef, useEffect, useState} from "react";
import ReCAPTCHA from "react-google-recaptcha";
import {toast} from "react-toastify";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {contactSave, editSaved} from "../store/contact";
import {connect} from "react-redux";
import {getAboutText} from "../store/aboutUs";

function Contact(props) {
    const {t, i18n} = useTranslation();
    const [fullname, setFullname] = useState('');
    const [disabledBtn, setDisabledBtn] = useState(true);
    const [theme, setTheme] = useState('');
    const [text, setText] = useState('');
    const [phone_number, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const captcha = createRef()

    function onChange(value) {
        setDisabledBtn(false)
    }

    function SendData(event) {
        event.preventDefault()
        if(!(/\S+@\S+\.\S+/.test(email)) || phone_number.length < 9){
            toast.error("Xatolik! Iltimos ma'lumotlarni boshqattan tekshirib ko'ring!", {theme:'colored'});
        }
        else{
            let data = {
                fullname,
                theme,
                phone_number,
                email,
                text
            }
            props.contactSave(data)
            captcha.current.reset()
            setDisabledBtn(true)
            setText('')
            setTheme('')
            setPhoneNumber('')
            setEmail('')
            setFullname('')
        }
    }
    useEffect(()=>{
        if (props.message === true) {
            toast.success(t('contact_success'), {theme:'colored'});
            props.editSaved()
        }
        else if(props.message === false){
            toast.error(t('contact_fail'), {theme:'colored'});
            props.editSaved()
        }
    }, [props.message])

    return <div>
        <Navbar/>
        <BreadcrumbPage props={'contact_us'}/>
        <div className={'page'}>
            <div className="container">
                <h2>{t('contact_us')}</h2>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="contact-map">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5995.586954014358!2d69.250144!3d41.291599!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x576333b21104d281!2zNDHCsDE3JzI5LjgiTiA2OcKwMTUnMDAuNSJF!5e0!3m2!1sru!2s!4v1669836518233!5m2!1sru!2s"
                                width="100%" height="450" loading="lazy"></iframe>
                        </div>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className={'contact-text'}>
                                    <FmdGoodIcon/>
                                    <p>
                                        {t('address')}:
                                        <span>{t('our_address')}</span>
                                    </p>
                                </div>
                                <div className="contact-text">
                                    <AccessTimeIcon/>
                                    <p>
                                        {t('work_time')}:
                                        <span>{t('our_work_time')}</span>
                                    </p>
                                </div>
                                <div className="contact-text">
                                    <EventBusyIcon/>
                                    <p>
                                        {t('busy_days')}:
                                        <span>{t('our_weekends')}</span>
                                    </p>
                                </div>
                                <div className="contact-text">
                                    <PhoneCallbackIcon/>
                                    <p>
                                        {t('phone')}:
                                        <span>{t('our_phone')}</span>
                                    </p>
                                </div>
                                <div className="contact-text">
                                    <FaxIcon/>
                                    <p>
                                        {t('fax')}:
                                        <span>{t('our_phone')}</span>
                                    </p>
                                </div>
                                <div className="contact-text">
                                    <AlternateEmailIcon/>
                                    <p>
                                        {t('email')}:
                                        <span>{t('our_email')}</span>
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6 form-block">
                                <h2 className={'form-contact-title'}>{t('contact_form_title')}</h2>
                                <form onSubmit={SendData}>
                                    <TextField required id="outlined-basic" label={t('fullname')} value={fullname} variant="outlined"
                                               onChange={(event)=>setFullname(event.target.value)} />
                                    <TextField id="outlined-basic" label={t('theme')} value={theme}  variant="outlined"
                                               onChange={(event)=>setTheme(event.target.value)} />
                                    <TextField required id="outlined-basic" label={t('phone')} value={phone_number} variant="outlined"
                                               onChange={(event)=>setPhoneNumber(event.target.value)} />
                                    <TextField required id="outlined-basic" label={t('email')} value={email} variant="outlined"
                                               onChange={(event)=>setEmail(event.target.value)} />
                                    <TextField
                                        required
                                        variant="outlined"
                                        id="outlined-basic"
                                        label={t('message')}
                                        multiline
                                        minRows={2}
                                        maxRows={4}
                                        value={text}
                                        onChange={(event)=>setText(event.target.value)}

                                    />
                                    <ReCAPTCHA
                                        ref={captcha}
                                        sitekey="6LfSKlQjAAAAAMx2eWUk9uHI52-vYuuXYlkycNg-"
                                        onChange={onChange}
                                    />
                                    <Button disabled={disabledBtn ? true : false} variant="contained" style={{backgroundColor: '#2b5ca8', padding: '10px 0', color: 'white'}} type={'submit'}>{t('send')}</Button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
}

export default connect(props=>props.contact_us, {contactSave, editSaved})(Contact)