import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';

export const Nav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
  @media screen and (max-width: 1000px) {
    padding: 0;
    margin-right: 0;
    width: 100%;
    max-width: 100%;
    margin-left: 0;
  }
`;

export const NavLink = styled(Link)`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: white;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 2rem;
  height: 100%;
  cursor: pointer;
  
`;


export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  background-color: #2b5ca8;
  @media screen and (max-width: 1000px) {
    opacity: 1;
    position: fixed;
    z-index: 1;
    transition-duration: .4s;
    top: -100vh;
    min-width: 100%;
    margin: 0;
    flex-direction: column;
    height: calc(100vh - 120px);
    background-color: #0A428C;
  }
`;

export const NavBtn = styled.div`
  display: flex;
  align-items: center;
`;

export const NavBtnLink = styled(Link)`
  font-family: Best RF Rufo;
  color: #B5954E;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  &:hover {
    transition: all 0.2s ease-in-out;
    color: #010606;
  }
  img{
    padding-right: 18px;
  }
`;