import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import {Link} from 'react-router-dom'
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";

function handleClick(event) {
    event.preventDefault();
}

export default function BreadcrumbPage(obj) {
    const {t, i18n} = useTranslation();
    const [links, setLinks] = useState([])

    useEffect(()=>{
        if(Array.isArray(obj.props)){
            setLinks(obj.props)
        }
    }, [obj.props])
    return (
        <div role="presentation" onClick={handleClick} className={'breadcrumb-page'}>
            <div className="container">
            <Breadcrumbs aria-label="breadcrumb">
                <Link to={"/"}>
                    {t('home_page')}
                </Link>
                {links ? links.length > 1 ? links.map((item, index)=><Link key={index} to={item.link}>
                    {index+1!== links.length ? t(item.title) : item.title}
                </Link>) : <Link>
                    {!Array.isArray(obj.props) ? t(obj.props) : ''}
                </Link> : ''}

            </Breadcrumbs>
            </div>
        </div>
    );
}