import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "./apiAction";

const slice = createSlice({
    name: 'branches',
    initialState: {branches: []},
    reducers:{
        getAllBranches: (state,action)=>{
            state.branches = action.payload
        }
    }
})

export const getBranches = () => apiCall({
    url: 'organizations/',
    method: 'GET',
    onSuccess: slice.actions.getAllBranches.type
})

export const getFilteredBranches = (id) => apiCall({
    url: 'organizations/?region='+id,
    method: 'GET',
    onSuccess: slice.actions.getAllBranches.type
})


export default slice.reducer