import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "./apiAction";

const slice = createSlice({
    name: 'video_gallery',
    initialState: {video_gallery: []},
    reducers:{
        getVideoGallery: (state,action)=>{
            state.video_gallery = action.payload
        }
    }
})

export const getAllVideoGallery = () => apiCall({
    url: 'video-gallery/',
    method: 'GET',
    onSuccess: slice.actions.getVideoGallery.type
})


export default slice.reducer