import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "./apiAction";

const slice = createSlice({
    name: 'faqs',
    initialState: {faqs: []},
    reducers:{
        getAllFaqs: (state,action)=>{
            state.faqs = action.payload
        }
    }
})

export const getFaqs = () => apiCall({
    url: 'faqs/',
    method: 'GET',
    onSuccess: slice.actions.getAllFaqs.type
})


export default slice.reducer