import Footer from "../blocks/footer";
import Navbar from "../blocks/navbar";
import BreadcrumbPage from "../blocks/breadcrumb";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {useEffect, useState} from "react";
import {getSearchDocuments, getSearchFaqs, getSearchNews, getSearchOpenDocuments} from "../store/search";
import {Link, useSearchParams} from "react-router-dom";
import SearchBlock from "../blocks/searchBlock";

function Search(props) {
    const {t, i18n} = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchResult, setSearchResult] = useState(false)
    const searchWord = searchParams.get("query")

    useEffect(() => {
        props.getSearchFaqs(searchWord)
        props.getSearchNews(searchWord)
        props.getSearchDocuments(searchWord)
        props.getSearchOpenDocuments(searchWord)
    }, [searchParams])


    useEffect(() => {
        if(!(props.documents.length || props.open_documents.length || props.news.length || props.faqs.length)){
            setSearchResult({notFound: true})
        }else{
            setSearchResult({
                documents: props.documents,
                open_documents: props.open_documents,
                news: props.news,
                faqs: props.faqs,
                notFound: false
            })
        }
    }, [props])

    return (
        <div>
            <Navbar/>
            <BreadcrumbPage props={'search'}/>
            <div className={'page'}>
                <div className="container search">
                    <h2>{t('search')}</h2>
                    <SearchBlock query={searchWord}/>
                    <ul className={`search-results ${searchResult.notFound ? 'notFound' : ''}`}>
                        {searchResult.news && searchResult.news.length ? searchResult.news.map((item, index)=>
                            <li key={item.title+index}>
                                <Link to={'/news/'+item.id}>{item[`title_${i18n.language}`]}</Link>
                            </li>
                        ): '' }
                        {searchResult.documents && searchResult.documents.length ? searchResult.documents.map((item, index)=>
                            <li key={item.title+index}>
                                <Link to={'/documents/'}>{item[`title_${i18n.language}`]}</Link>
                            </li>
                        ): '' }
                        {searchResult.open_documents && searchResult.open_documents.length ? searchResult.open_documents.map((item, index)=>
                            <li key={item.title+index}>
                                <Link to={'/open-data/'}>{item[`title_${i18n.language}`]}</Link>
                            </li>
                        ): '' }
                        {searchResult.faqs && searchResult.faqs.length ? searchResult.faqs.map((item, index)=>
                            <li key={item.id+item.question}>
                                <Link to={'/faqs/'+item.id}>{item[`question_${i18n.language}`]}</Link>
                            </li>
                        ): '' }
                        {searchResult.notFound ?
                            <li className={'notFound'}>
                                {t('notFound')}
                            </li>
                        : '' }

                    </ul>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default connect(props => props.search, {
    getSearchFaqs,
    getSearchNews,
    getSearchOpenDocuments,
    getSearchDocuments
})(Search)