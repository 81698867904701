import Carousel from 'react-bootstrap/Carousel';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import {useEffect, useState} from "react";
import {connect} from "react-redux";
import {getAllSliders} from "../store/slider";
import {Link} from "react-router-dom";
import { format } from 'date-fns'
import {useTranslation} from "react-i18next";

function CarouselFade(props) {
    const {t, i18n} = useTranslation();
    const [sliders, setSliders] = useState(false)

    useEffect(()=>{
        props.getAllSliders()
    }, [])

    useEffect(()=>{
        setSliders(props.sliders)
    }, [props])

    return (
        <Carousel fade>
            {sliders ? sliders.map((item, index)=> <Carousel.Item key={index}>
                <Link to={item.category === 1 ? `/news/${item.id}` : `/events/${item.id}`}>
                    <img
                        className="d-block w-100 carousel-image"
                        src={item.photo}
                        alt="slide"
                    />
                    <div className="slide-gr"/>
                    <Carousel.Caption>
                        <span><CalendarMonthIcon/>{format(new Date(item.upload_to), 'dd/MM/yyyy')}</span>
                        <h3>{item[`title_${i18n.language}`]}</h3>
                    </Carousel.Caption>
                </Link>
            </Carousel.Item> )  : ''}
        </Carousel>
    );
}

export default connect(({slider: {sliders}}) => ({sliders}), {getAllSliders})(CarouselFade);