import BreadcrumbPage from "../blocks/breadcrumb";
import Footer from "../blocks/footer";
import * as React from "react";
import Navbar from "../blocks/navbar";
import {useTranslation} from "react-i18next";
import NewsBlock from "../blocks/newsBlock";
import {useState} from "react";
import {Link} from "react-router-dom";

function News() {
    const {t, i18n} = useTranslation();
    const [newsFilter, setNewsFilter] = useState('our-news');

    return <div>
        <Navbar/>
        <BreadcrumbPage props={'news'}/>
        <div className={'page news'}>
            <div className="container">
                <div className={'news-header'}>
                    <h2>{t('news')}</h2>
                    <ul className={'news-filter'}>
                        <li className={'active dis-link'}>
                            <Link to={'#'}>{t('our_news')}</Link>
                        </li>
                        <li>
                            <Link to={'/events'}>{t('events')}</Link>
                        </li>
                    </ul>
                </div>
                <div className={''}>
                    <NewsBlock newsFilter={newsFilter}/>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
}

export default News