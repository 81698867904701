import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Provider} from "react-redux";
import store from "./store/store";
import './i18n';
import Loader from "./blocks/loader";
import AboutUs from "./pages/aboutUs";
import Leadership from "./pages/leadership";
import AllBranches from "./pages/allBranches";
import Documents from "./pages/documents";
import Events from "./pages/events";
import News from "./pages/news";
import PhotoGallery from "./pages/photoGallery";
import './fonts/static/Raleway-Medium.ttf'
import './fonts/static/Raleway-Regular.ttf'
import './fonts/static/Raleway-SemiBold.ttf'
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import GallerySingle from "./pages/gallerySingle";
import VideoGallery from "./pages/videoGallery";
import OpenDocuments from "./pages/openDocuments";
import Contact from "./pages/contact";
import 'react-toastify/dist/ReactToastify.css';
import NewsInner from "./pages/newsInner";
import InnerEvents from "./pages/innerEvents";
import Faqs from "./pages/faqs";
import Search from "./pages/search";
import NotFound from "./pages/notfound";
import ToLogin from "./pages/toLogin";
import KojCompanies from "./pages/kojCompanies";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <Suspense fallback={<Loader/>}>
            <Provider store={store}>
                <Routes>
                    <Route path={'/'} element={<App/>}/>
                    <Route path={'/about-us/'} element={<AboutUs/>}/>
                    <Route path={"/managment/"} element={<Leadership/>} />
                    <Route path={"/all-resources/"} element={<AllBranches/>} />
                    <Route path={"/documents/"} element={<Documents/>} />
                    <Route path={"/events/"} element={<Events/>} />
                    <Route path={"/events/:id/"} element={<InnerEvents/>} />
                    <Route path={"/news/"} element={<News/>} />
                    <Route path={"/news/:id/"} element={<NewsInner/>} />
                    <Route path={"/photo-gallery/"} element={<PhotoGallery/>} />
                    <Route path={"/photo-gallery/:id/"} element={<GallerySingle/>} />
                    <Route path={"/video-gallery/"} element={<VideoGallery/>} />
                    <Route path={"/open-data/"} element={<OpenDocuments/>} />
                    <Route path={"/uzkoj-companies/"} element={<KojCompanies/>} />
                    <Route path={"/contact-us/"} element={<Contact/>} />
                    <Route path={"/faqs/"} element={<Faqs/>} />
                    <Route path={"/faqs/:id/"} element={<Faqs/>} />
                    <Route path={"/to-login/"} element={<ToLogin/>} />
                    <Route path={"/search/"} element={<Search/>}>
                        <Route path=":query" element={<Search/>} />
                    </Route>
                    <Route path={"*"} element={<NotFound/>} />
                </Routes>
            </Provider>
        </Suspense>
    </BrowserRouter>
);

