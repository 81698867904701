import myMap from "./myMap";
import {SVGMap} from "react-svg-map";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import "react-svg-map/lib/index.css";
import {useEffect, useState} from "react";
import './map.css'
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {getBranches, getFilteredBranches} from "../store/allBranches";


function Map(props) {
    const {t, i18n} = useTranslation();
    const [fixedTitle, setFixedTitle] = useState('');
    const [stateTitle, setStateTitle] = useState("organizations");
    const [stateTitleName, setStateTitleName] = useState('');
    const [positionLeft, setPositionLeft] = useState('');
    const [positionTop, setPositionTop] = useState('');
    const [companies, setCompanies] = useState([]);

    useEffect(()=>{
        props.getBranches()
    }, [])

    useEffect(()=>{
        setCompanies(props.branches)
    }, [props.branches])

    function getRegionId(value){
        switch (value){
            case 'andijan':
                return '1'
            case 'karakalpakstan':
                return '2'
            case 'navoiy':
                return '3'
            case 'xorazm':
                return '4'
            case 'bukhara':
                return '5'
            case 'samarqand':
                return '6'
            case 'qashqadaryo':
                return '7'
            case 'surxondaryo':
                return '8'
            case 'jizzakh':
                return '9'
            case 'sirdaryo':
                return '10'
            case 'tashkent':
                return '11'
            case 'tashkent-city':
                return '12'
            case 'namangan':
                return '13'
            case 'fergana':
                return '14'
            default:
                return value
        }
    }

    function stateName(e){
        return e.target.id
    }

    function selectState(e) {
        setStateTitle(stateName(e))
        setStateTitleName(e.target.id)
        props.getFilteredBranches(getRegionId(e.target.id))
    }

    function fixedState(e){
        setFixedTitle(stateName(e))
    }

    function overMouse(){
        setFixedTitle('')
        setPositionLeft('')
        setPositionTop('')
    }

    function stateFixedTitleFunc(event){
        setPositionLeft(event.clientX+5)
        setPositionTop(event.clientY-90)
    }

    return (
        <div className="Map">
            <div style={{width: '66%'}}>
                <SVGMap map={myMap} onLocationClick={(event) => selectState(event)}
                        onLocationMouseOver={(event) => fixedState(event)}
                        onLocationMouseOut={() => overMouse()}
                        onLocationMouseMove={(event)=>stateFixedTitleFunc(event)}
                />
            </div>
            <div className={'companies'} style={{width: '34%'}}>
                {stateTitle?
                    <h3>{t(stateTitle)}<span> {t('map_subtitle')}</span></h3>
                    :''
                }
                <div>
                    {companies?companies.map((item, index)=>(
                        <div className="company-item" key={index}>
                            <h5 className="company-title">{item[`title_${i18n.language}`]}</h5>
                            <p>{t('address')}: {item[`address_${i18n.language}`]}<br/>
                                {t('hotline')}: {item.phone_number}</p>
                        </div>
                    )):''}
                </div>
                {props.mainPage && companies.length > 6 ?
                    <div className="more" style={{float:'right'}}>
                        <Link to="/all-resources">{t('read_all')}<ChevronRightIcon/></Link>
                    </div>
                    : ''}
            </div>
            <div className={'fixed-title-map'} style={{left: positionLeft, top: positionTop}}>
                <span>{t(fixedTitle)}</span>
            </div>
        </div>
    );
}

export default connect(props=>props.branches, {getBranches, getFilteredBranches})(Map)