import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Link, useNavigate, useParams} from "react-router-dom";

function SearchBlock(props) {
    const navigate = useNavigate();
    const {t, i18n} = useTranslation();
    const params = useParams()
    const [searchWord, setSearchWord] = useState('');

    function handleKeyPress(key){
        if(key === 'Enter' && searchWord!== ''){
            navigate('/search/?query='+searchWord)
        }
    }
    function handleChange(event) {
        setSearchWord(event.target.value);
    };
    function searchingFunc(){
        if(searchWord!== '') {
            navigate('/search/?query=' + searchWord)
        }
    }

    useEffect(()=>{
        if(searchWord!== ''){
            setSearchWord(props.query)
        }
    }, [props])

    return <div className={'search-block'}>
        <div className={'container'}>
        <span>
            <TextField fullWidth
                         className={'search-input'}
                         label={t('search_placeholder')} variant="outlined" onKeyPress={(e) => handleKeyPress(e.key)}
                         value={searchWord} onChange={(e) => handleChange(e)}
        />
                        <SearchIcon className={'icon-search'} onClick={searchingFunc}/></span>
            <span>{t('search_example')}: <Link to={'/search/?query='+t('region_branches')}>{t('region_branches')}</Link>, <Link to={'/search/?query='+t('our_news')}>{t('our_news')}</Link></span>
        </div>
    </div>
}

export default SearchBlock