import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import ReactPaginate from "react-paginate";
import {connect} from "react-redux";
import {getFilteredNews, getNews} from "../store/news";
import {format} from "date-fns";

function NewsBlock(props) {
    const {t, i18n} = useTranslation();
    const [posts, setPosts] = useState([])

    useEffect(() => {
        if(props.mainPage){
            props.getNews()
        }
    }, [])

    useEffect(() => {
        setPosts(props.news)
    }, [props.news])

    useEffect(() => {
        let newsFilter = props.newsFilter
        if (newsFilter === 'events') {
            props.getFilteredNews('2')
        } else if (newsFilter === 'our-news') {
            props.getFilteredNews('1')
        } else {
            props.getNews()
        }

        if (props && props.mainPage) {
            setItemsPerPage(6)
        }

    }, [props.newsFilter])


    // Pagination

    const [itemsPerPage, setItemsPerPage] = useState(12)
    const [itemOffset, setItemOffset] = useState(0);

    const endOffset = itemOffset + itemsPerPage;
    const currentItems = posts.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(posts.length / itemsPerPage);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % posts.length;
        setItemOffset(newOffset);
    };

    // End Pagination

    return <div className="row">
        {currentItems.length ? currentItems.map((item, index) => <div className={index===0 || index===1 ? 'col-lg-6' : 'col-lg-3'} key={index}>
            <Link to={item.category === 1 ? '/news/' + item.id : '/events/' + item.id}>
                <div className="card-news">
                    <div className="header-card">
                        <img src={item.photo} alt={item[`title_${i18n.language}`]}/>
                    </div>
                    <div className="main-card">
                        <span>{item[`title_${i18n.language}`]}</span>
                        <hr/>
                        <p>
                            <span className={'date-news'}>{format(new Date(item.upload_to), 'dd/MM/yyyy')}</span>
                            <Link to={item.category === 1 ? '/news/' : '/events/'}>
                                {item.category === 1 ? t('news') : t('events')}</Link>
                        </p>
                    </div>
                </div>
            </Link>
        </div>) : ''}
        {pageCount > 1 && !props.mainPage ? <div className={'pagination-news'}>
            <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="<"
                renderOnZeroPageCount={null}
            />
        </div> : ''}

    </div>
}

export default connect(props => props.news, {getNews, getFilteredNews})(NewsBlock)
