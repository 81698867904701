import Modal from '@mui/material/Modal';
import {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    height: '500px',
    overflow: 'auto',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

export default function ModalForManagment(props) {
    useEffect(()=>{
        setOpen(props.isOpen)
        setMngPosition(props.mngPosition)
        setMngFullname(props.mngFullname)
        setMngText(props.mngText)
    }, [props.isOpen])

    const [open, setOpen] = useState(true);
    const [mngPosition, setMngPosition] = useState('')
    const [mngFullname, setMngFullname] = useState('')
    const [mngText, setMngText] = useState('')

    function handleClose(){
        setOpen(false);
        if(props && props.setIsOpen){
            props.setIsOpen(false)
        }
    }

    return (
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={style} className={'mngModal'}>
                    <span className={'close-video'} onClick={handleClose}><CloseIcon/></span>
                <h4>{mngPosition}</h4>
                <h3>{mngFullname}</h3>
                <p dangerouslySetInnerHTML={{__html: mngText}}/>
                </Box>
            </Modal>
    );
}