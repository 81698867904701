import {useTranslation} from "react-i18next";
import Navbar from "../blocks/navbar";
import BreadcrumbPage from "../blocks/breadcrumb";
import {Link} from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Footer from "../blocks/footer";
import {styled} from "@mui/material/styles";
import Button from "@mui/material/Button";
import * as React from "react";
import ModalForManagment from "../blocks/modalForManagment";
import {useEffect, useState} from "react";
import {connect} from "react-redux";
import {getInnerNews, getNews} from "../store/news";
import {getPersonManagement} from "../store/management";

const ColorButton = styled(Button)(({theme}) => ({
    color: '#2c60a8',
    backgroundColor: '#ededed',
    textTransform: 'capitalize',
    fontWeight: '500',
    '&:hover': {
        backgroundColor: '#2c60a8',
        color: '#ffffff'
    },
}));

function Leadership(props) {
    const {t, i18n} = useTranslation();
    const [isOpen, setIsOpen] = useState(false)
    const [mngPosition, setMngPosition] = useState('')
    const [mngFullname, setMngFullname] = useState('')
    const [mngText, setMngText] = useState('')
    const [leaders, setLeaders] = useState([
        {
            "mng_img": "https://soliq.uz/storage/leadership/May2020/7610c858cffe4be9ba44e9aefdcf71e4_14052020.jpg",
            "mng_position": "Davlat soliq qo'mitasi Raisi",
            "mng_fullname": "Kudbiyev Sherzod Davlyatovich",
            "mng_resp": "Har kuni 09.00 dan 11.00 gacha",
            "mng_phone": "+998 71 244-97-02",
            "mng_email": "info@koj.uz",
            "mng_boigraphy": "Sherzod Davlyatovich Kudbiyev 1981-yil Farg‘ona viloyatida tug‘ilgan.<br/><br/>\n" +
                "                    2000-yilda Farg‘ona politexnika institutining «Moliya va kredit» yo‘nalishi bo‘yicha bakalavriat\n" +
                "                    ta’limni va 2002-yilda «Menejment» yo‘nalishida ushbu institutning magistratura bosqichini\n" +
                "                    tamomlagan.\n" +
                "                    <br/><br/>\n" +
                "                    Sh.Kudbiev mehnat faoliyatini 2002 yilda Farg‘ona politexnika institutining «Moliya», «Iqtisodiyot»\n" +
                "                    kafedralari assistenti sifatida boshlagan, «Buxgalteriya hisobi va audit» kafedrasida katta\n" +
                "                    o‘qituvchi bo‘lib ishlagan.\n" +
                "                    <br/><br/>\n" +
                "                    2007-yildan 2010-yilgacha Mehnat va aholini ijtimoiy muhofaza qilish vazirligida turli lavozimlarda\n" +
                "                    faoliyat yuritgan.\n" +
                "                    <br/><br/>\n" +
                "                    2010-yildan 2012-yilgacha O‘zbekiston Respublikasi Vazirlar Mahkamasi Makroiqtisodiy rivojlantirish,\n" +
                "                    iqtisodiyotdagi tarkibiy o‘zgartirishlar va hududlarni kompleks rivojlantirish masalalari yig‘ma\n" +
                "                    axborot-tahlil departamentining yetakchi mutaxassisi, bosh mutaxassisi bo‘lib ishlagan.\n" +
                "                    <br/><br/>\n" +
                "                    2012-yildan 2015-yilgacha Mehnat va aholini ijtimoiy muhofaza qilish vazirligi Respublika aholi\n" +
                "                    bandligi, mehnatni muhofaza qilish va aholini ijtimoiy muhofaza qilish ilmiy markazi direktori\n" +
                "                    bo‘lgan.\n" +
                "                    <br/><br/>\n" +
                "                    2015-2016-yillarda Vazirlar Mahkamasi Yig‘ma axborot-tahlil departamenti mudirining birinchi\n" +
                "                    o‘rinbosari lavozimida ishlagan.\n" +
                "                    <br/><br/>\n" +
                "                    2016-yildan 2018-yilgacha Toshkent shahri Mirzo Ulug‘bek tumani hokimi bo‘lgan.\n" +
                "                    <br/><br/>\n" +
                "                    2018-2019-yillarda Bandlik va mehnat munosabatlari vaziri lavozimida faoliyat olib borgan.\n" +
                "                    <br/><br/>\n" +
                "                    2019-yildan 2020-yilgacha O‘zbekiston Respublikasi Prezidentining davlat xizmati va hokimiyat\n" +
                "                    vakillik organlari bilan hamkorlik qilish masalalari bo‘yicha maslahatchisi bo‘lgan.\n" +
                "                    <br/><br/>\n" +
                "                    2020 yil may oyidan O‘zbekiston Respublikasi Davlat soliq qo‘mitasi raisi lavozimida ishlab\n" +
                "                    kelmoqda.\n" +
                "                    <br/><br/>\n" +
                "                    Sh.Kudbiev «Soliq xizmatining III darajali davlat maslahatchisi» unvoniga ega.\n" +
                "                    <br/><br/>\n" +
                "                    Oilali, uch nafar farzandi bor.",
            "mng_work": "Ish majburiyati",
        },
        {
            "mng_img": "https://soliq.uz/storage/leadership/May2020/7610c858cffe4be9ba44e9aefdcf71e4_14052020.jpg",
            "mng_position": "Davlat soliq qo'mitasi Raisi",
            "mng_fullname": "Kudbiyev Sherzod Davlyatovich",
            "mng_resp": "Har kuni 09.00 dan 11.00 gacha",
            "mng_phone": "+998 71 244-97-02",
            "mng_email": "info@koj.uz",
            "mng_boigraphy": "Sherzod Davlyatovich Kudbiyev 1981-yil Farg‘ona viloyatida tug‘ilgan.<br/><br/>\n" +
                "                    2000-yilda Farg‘ona politexnika institutining «Moliya va kredit» yo‘nalishi bo‘yicha bakalavriat\n" +
                "                    ta’limni va 2002-yilda «Menejment» yo‘nalishida ushbu institutning magistratura bosqichini\n" +
                "                    tamomlagan.\n" +
                "                    <br/><br/>\n" +
                "                    Sh.Kudbiev mehnat faoliyatini 2002 yilda Farg‘ona politexnika institutining «Moliya», «Iqtisodiyot»\n" +
                "                    kafedralari assistenti sifatida boshlagan, «Buxgalteriya hisobi va audit» kafedrasida katta\n" +
                "                    o‘qituvchi bo‘lib ishlagan.\n" +
                "                    <br/><br/>\n" +
                "                    2007-yildan 2010-yilgacha Mehnat va aholini ijtimoiy muhofaza qilish vazirligida turli lavozimlarda\n" +
                "                    faoliyat yuritgan.\n" +
                "                    <br/><br/>\n" +
                "                    2010-yildan 2012-yilgacha O‘zbekiston Respublikasi Vazirlar Mahkamasi Makroiqtisodiy rivojlantirish,\n" +
                "                    iqtisodiyotdagi tarkibiy o‘zgartirishlar va hududlarni kompleks rivojlantirish masalalari yig‘ma\n" +
                "                    axborot-tahlil departamentining yetakchi mutaxassisi, bosh mutaxassisi bo‘lib ishlagan.\n" +
                "                    <br/><br/>\n" +
                "                    2012-yildan 2015-yilgacha Mehnat va aholini ijtimoiy muhofaza qilish vazirligi Respublika aholi\n" +
                "                    bandligi, mehnatni muhofaza qilish va aholini ijtimoiy muhofaza qilish ilmiy markazi direktori\n" +
                "                    bo‘lgan.\n" +
                "                    <br/><br/>\n" +
                "                    2015-2016-yillarda Vazirlar Mahkamasi Yig‘ma axborot-tahlil departamenti mudirining birinchi\n" +
                "                    o‘rinbosari lavozimida ishlagan.\n" +
                "                    <br/><br/>\n" +
                "                    2016-yildan 2018-yilgacha Toshkent shahri Mirzo Ulug‘bek tumani hokimi bo‘lgan.\n" +
                "                    <br/><br/>\n" +
                "                    2018-2019-yillarda Bandlik va mehnat munosabatlari vaziri lavozimida faoliyat olib borgan.\n" +
                "                    <br/><br/>\n" +
                "                    2019-yildan 2020-yilgacha O‘zbekiston Respublikasi Prezidentining davlat xizmati va hokimiyat\n" +
                "                    vakillik organlari bilan hamkorlik qilish masalalari bo‘yicha maslahatchisi bo‘lgan.\n" +
                "                    <br/><br/>\n" +
                "                    2020 yil may oyidan O‘zbekiston Respublikasi Davlat soliq qo‘mitasi raisi lavozimida ishlab\n" +
                "                    kelmoqda.\n" +
                "                    <br/><br/>\n" +
                "                    Sh.Kudbiev «Soliq xizmatining III darajali davlat maslahatchisi» unvoniga ega.\n" +
                "                    <br/><br/>\n" +
                "                    Oilali, uch nafar farzandi bor.",
            "mng_work": "Ish majburiyati",
        },
        {
            "mng_img": "https://soliq.uz/storage/leadership/May2020/7610c858cffe4be9ba44e9aefdcf71e4_14052020.jpg",
            "mng_position": "Davlat soliq qo'mitasi Raisi",
            "mng_fullname": "Kudbiyev Sherzod Davlyatovich",
            "mng_resp": "Har kuni 09.00 dan 11.00 gacha",
            "mng_phone": "+998 71 244-97-02",
            "mng_email": "info@koj.uz",
            "mng_boigraphy": "Sherzod Davlyatovich Kudbiyev 1981-yil Farg‘ona viloyatida tug‘ilgan.<br/><br/>\n" +
                "                    2000-yilda Farg‘ona politexnika institutining «Moliya va kredit» yo‘nalishi bo‘yicha bakalavriat\n" +
                "                    ta’limni va 2002-yilda «Menejment» yo‘nalishida ushbu institutning magistratura bosqichini\n" +
                "                    tamomlagan.\n" +
                "                    <br/><br/>\n" +
                "                    Sh.Kudbiev mehnat faoliyatini 2002 yilda Farg‘ona politexnika institutining «Moliya», «Iqtisodiyot»\n" +
                "                    kafedralari assistenti sifatida boshlagan, «Buxgalteriya hisobi va audit» kafedrasida katta\n" +
                "                    o‘qituvchi bo‘lib ishlagan.\n" +
                "                    <br/><br/>\n" +
                "                    2007-yildan 2010-yilgacha Mehnat va aholini ijtimoiy muhofaza qilish vazirligida turli lavozimlarda\n" +
                "                    faoliyat yuritgan.\n" +
                "                    <br/><br/>\n" +
                "                    2010-yildan 2012-yilgacha O‘zbekiston Respublikasi Vazirlar Mahkamasi Makroiqtisodiy rivojlantirish,\n" +
                "                    iqtisodiyotdagi tarkibiy o‘zgartirishlar va hududlarni kompleks rivojlantirish masalalari yig‘ma\n" +
                "                    axborot-tahlil departamentining yetakchi mutaxassisi, bosh mutaxassisi bo‘lib ishlagan.\n" +
                "                    <br/><br/>\n" +
                "                    2012-yildan 2015-yilgacha Mehnat va aholini ijtimoiy muhofaza qilish vazirligi Respublika aholi\n" +
                "                    bandligi, mehnatni muhofaza qilish va aholini ijtimoiy muhofaza qilish ilmiy markazi direktori\n" +
                "                    bo‘lgan.\n" +
                "                    <br/><br/>\n" +
                "                    2015-2016-yillarda Vazirlar Mahkamasi Yig‘ma axborot-tahlil departamenti mudirining birinchi\n" +
                "                    o‘rinbosari lavozimida ishlagan.\n" +
                "                    <br/><br/>\n" +
                "                    2016-yildan 2018-yilgacha Toshkent shahri Mirzo Ulug‘bek tumani hokimi bo‘lgan.\n" +
                "                    <br/><br/>\n" +
                "                    2018-2019-yillarda Bandlik va mehnat munosabatlari vaziri lavozimida faoliyat olib borgan.\n" +
                "                    <br/><br/>\n" +
                "                    2019-yildan 2020-yilgacha O‘zbekiston Respublikasi Prezidentining davlat xizmati va hokimiyat\n" +
                "                    vakillik organlari bilan hamkorlik qilish masalalari bo‘yicha maslahatchisi bo‘lgan.\n" +
                "                    <br/><br/>\n" +
                "                    2020 yil may oyidan O‘zbekiston Respublikasi Davlat soliq qo‘mitasi raisi lavozimida ishlab\n" +
                "                    kelmoqda.\n" +
                "                    <br/><br/>\n" +
                "                    Sh.Kudbiev «Soliq xizmatining III darajali davlat maslahatchisi» unvoniga ega.\n" +
                "                    <br/><br/>\n" +
                "                    Oilali, uch nafar farzandi bor.",
            "mng_work": "Ish majburiyati",
        },
    ])


    useEffect(() => {
        props.getPersonManagement()
    }, [])


    useEffect(() => {
        setLeaders(props.management)
    }, [props])


    function SetModal(val, position, fullname, text) {
        setIsOpen(val)
        setMngPosition(position)
        setMngFullname(fullname)
        setMngText(text)
    }


    return (
        <div>
            <Navbar/>
            <BreadcrumbPage props={'management'}/>
            <div className={'page'}>
                <div className="container">
                    <div className="row">
                        {isOpen ? <ModalForManagment isOpen={isOpen} setIsOpen={setIsOpen} mngPosition={mngPosition}
                                                     mngFullname={mngFullname} mngText={mngText}/> : ''}
                        <div className="col-lg-8">
                            <h2>{t('management')}</h2>
                            {leaders ? leaders.map((item, index) =>
                                <div className="item-managment" key={index}>
                                    <div className="col-lg-3">
                                        <img
                                            src={item.image}
                                            className="mng-img" alt={item.fullname}/>
                                    </div>
                                    <div className="col-lg-6">
                                        <p className="mng-position">{item[`position_${i18n.language}`]}</p>
                                        <p className="mng-fullname">{item[`fullname_${i18n.language}`]}</p>
                                        <p className="mng-resp">
                                            <span>{t('res_days')}:</span>{item[`reseption_days_${i18n.language}`]}</p>
                                        <p className="mng-phone">
                                            <span>{t('phone_fax')}:</span>
                                            <a target={'_blank'} href={'tel:' + item[`phone_number`]}>
                                                {item[`phone_number`]}
                                            </a>
                                        </p>
                                        <p className="mng-mail">
                                            <span>{t('email')}:</span>
                                            <a target={'_blank'} href={'mailto:' + item[`email`]}>
                                                {item[`email`]}
                                            </a>
                                        </p>
                                    </div>
                                    <div className="col-lg-3 btn-mng">
                                        <ColorButton variant="contained"
                                                     onClick={() => SetModal(!isOpen, item[`position_${i18n.language}`], item[`fullname_${i18n.language}`], item[`authority_${i18n.language}`])}>{t('work_commitment')}</ColorButton>
                                        <ColorButton variant="contained"
                                                     onClick={() => SetModal(!isOpen, item[`position_${i18n.language}`], item[`fullname_${i18n.language}`], item[`biography_${i18n.language}`])}>{t('biography')}</ColorButton>
                                    </div>
                                </div>
                            ) : ''}

                        </div>
                        <div className="col-lg-4">
                            <ul className="list-about-us sticky-top">
                                <li>
                                    <Link to={'/about-us'}>{t('about_society')}<ArrowForwardIosIcon/></Link>
                                </li>
                                <li className={'active'}>
                                    <Link to={'/'}>{t('management')}<ArrowForwardIosIcon/></Link>
                                </li>
                                <li>
                                    <Link to={'/all-resources'}>{t('organizations')}<ArrowForwardIosIcon/></Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default connect(props => props.management, {getPersonManagement})(Leadership)