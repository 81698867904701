import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "./apiAction";

const slice = createSlice({
    name: 'koj_companies',
    initialState: {koj_companies: []},
    reducers:{
        getKojCompanies: (state,action)=>{
            state.koj_companies = action.payload
        }
    }
})

export const getAllKojCompanies = () => apiCall({
    url: 'koj-companies/',
    method: 'GET',
    onSuccess: slice.actions.getKojCompanies.type
})


export default slice.reducer