import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "./apiAction";

const slice = createSlice({
    name: 'resources',
    initialState: {resources: []},
    reducers:{
        getAllResources: (state,action)=>{
            state.resources = action.payload
        }
    }
})

export const getResources = () => apiCall({
    url: 'resources/',
    method: 'GET',
    onSuccess: slice.actions.getAllResources.type
})


export default slice.reducer