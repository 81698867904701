import {useEffect, useState} from "react";
import Navbar from './blocks/navbar'
import CarouselFade from "./blocks/carousel";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Map from './map/map'
import TickerNews from "./blocks/ticker";
import Footer from "./blocks/footer";
import GallerySlider from "./blocks/gallerySlider";
import './App.css';
import NewsBlock from "./blocks/newsBlock";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {getResources} from "./store/resources";
import {getFaqs} from "./store/faq";


function App(props) {

    const {t, i18n} = useTranslation();
    const [newsFilter, setNewsFilter] = useState('all');
    const [resources, setResources] = useState([]);
    const [faqs, setFaqs] = useState([]);

    useEffect(() => {
        props.getResources()
        props.getFaqs()
    }, [])

    useEffect(() => {
        setResources(props.resources.resources)
        setFaqs(props.faqs.faqs.slice(0, 8))
    }, [props])

    return (
        <div className="App">
            <Navbar/>
            <div className="marquee-section">
                <TickerNews/>
            </div>
            <div className="container">
                <div className={'main-section'}>
                    <div className="carousel-section">
                        <CarouselFade/>
                    </div>
                </div>
                <div className="questions-section">
                    <div className="header-section">
                        <h2>{t('faqs')}</h2>
                        <Link to="/faqs/">{t('all_questions')}<ChevronRightIcon/></Link>
                    </div>
                    <div className="main-section">
                        <ul>
                            {faqs.length ? faqs.map((item, index)=>
                                <li key={index}>
                                    <HelpOutlineIcon className={'qs-icon'}/>
                                    <Link to={`/faqs/`+item.id}>{item[`question_${i18n.language}`].length > 80 ? item[`question_${i18n.language}`].substring(0, 80)+'...' : item[`question_${i18n.language}`]}</Link>
                                </li>
                            ): ''}
                        </ul>
                    </div>
                </div>
            </div>
            <div className="companies-section">
                <div className="container">
                    <h2>{t('organizations')}</h2>
                    <Map mainPage={1}/>
                </div>
            </div>
            <div className="container">
                <div className="news-section">
                    <div className="header-section">
                        <h2>{t('news')} | {t('events')}</h2>
                        <div>
                            <ul className={'news-filter'}>
                                <li onClick={() => setNewsFilter('all')}
                                    className={`${newsFilter === "all" ? "active" : ""}`}>{t('all')}
                                </li>
                                <li onClick={() => setNewsFilter('our-news')}
                                    className={`${newsFilter === "our-news" ? "active" : ""}`}>{t('our_news')}
                                </li>
                                <li onClick={() => setNewsFilter('events')}
                                    className={`${newsFilter === "events" ? "active" : ""}`}>{t('events')}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="news-main">
                        <NewsBlock mainPage={1} newsFilter={newsFilter}/>
                        <p className={'text-center all-news-btn'}>
                            <Link to={newsFilter === "our-news" || newsFilter === "all" ? '/news' : '/events'}>
                                {newsFilter === "our-news" || newsFilter === "all" ? t('all_news') : t('all_events')}
                                <ChevronRightIcon/>
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
            <div className="gallery-section">
                <div className="container">
                    <div className="header-section">
                        <h2>{t('photogallery')}</h2>
                    </div>
                </div>
                <GallerySlider/>
            </div>
            <div className="resources-section">
                <div className="container">
                    <div className="header-section">
                        <h2>{t('useful_resources')}</h2>
                    </div>
                    <div className="resources-main">
                        <div className="row">
                            {resources.length ? resources.map((item, index) =>
                                <div className="col-lg-3" key={index}>
                                    <a href={item.link} target={'_blank'}>
                                        <img src={item.image}
                                             alt={item.title}/>
                                        <span>{item[`title_${i18n.language}`]}</span>
                                    </a>
                                </div>
                            ) : ''}
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default connect(props => props, {getResources, getFaqs})(App);
